import React from "react"
import { Link } from "gatsby"

import Layout from "../components/bs/layout"
//import Image from "../components/image"
import SEO from "../components/bs/seo"

import MainImg from "../images/main_bg.jpg"

import shape1 from "../images/dec/1.png"
import shape2 from "../images/dec/2.png"
import shape3 from "../images/dec/3.png"
import shape4 from "../images/dec/4.png"
import shape5 from "../images/dec/5.png"
import shape6 from "../images/dec/6.png"
import shape7 from "../images/dec/7.png"
import shape8 from "../images/dec/8.png"

const Partnerstvo = () => (
  <Layout>
    <SEO
      title="IMMMUNO NATURA - BUDI NAŠ PARTNER !"
      description="Volite prirodu i rad sa prirodnim proizvodima? Radilo se o pojedincu ili kompaniji nudimo Vam atraktivne i fer uslove saradnje."
    />

    <section
      id="_heroPage"
      className="hero is-large _partnerstvo"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">PARTNERSTVO</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column _headline">
                <div className="content">
                  <h2 className="subtitle is-4">
                    IMMMUNO NATURA proizvodi nastaju kao plod posvećenosti
                    prirodi i brižnog rada na selekciji najboljih receptura sa
                    blagotvornim dejstvima.
                  </h2>
                  <p>
                    Prirodni resursi i znanje o mogućnostima njihovog
                    kori&scaron;tenja su praktično neiscrpni. Svjesni smo da
                    znanje i inspiracija nisu ničije ekskluzivno pravo i da ih
                    dobijaju i posjeduju mnogi. Iz tog razloga upoznavanje,
                    razmjena mi&scaron;ljenja i iskustava, saradnja i
                    partnerstvo spadaju među najvažnije principe na&scaron;eg
                    rada.
                  </p>
                  <p>
                    Uvijek smo otvoreni za savjete, za nove ideje, proizvodne
                    inovacije i naravno za kooperaciju na svim nivoima,
                    počev&scaron;i od idejnih rje&scaron;enja i razvoja
                    proizvoda preko proizvodnog procesa do
                    distribucije.&nbsp;&nbsp;
                  </p>
                  <p>
                    <strong>
                      Volite prirodu, imate ideje i/ili iskustvo u radu sa
                      prirodnim recepturama i proizvodima? Radilo se o pojedincu
                      ili kompaniji nudimo Vam atraktivne i fer uslove saradnje.
                    </strong>
                  </p>
                  <p>
                    <strong>
                      Radujemo se Va&scaron;em{" "}
                      <Link to="/postani-partner" title="BUDI NAŠ PARTNER !">
                        upitu
                      </Link>
                      !
                    </strong>
                  </p>
                  <Link
                    to="/postani-partner"
                    title="BUDI NAŠ PARTNER !"
                    className="button is-primary is-rounded is-medium btn1head"
                  >
                    POSTANI PARTNER
                  </Link>
                  <br />
                  <br />
                  <p>Odgovaramo u roku od 24 sata radnim danima.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Partnerstvo
